<ng-container *ngIf="show">
    <div class="dialog" id="dialog">
        <div class="loading" *ngIf="loading"></div>
        <div *ngIf="!loading">
            <p>
                L'importo delle quote richiesto dall'esercente è inferiore al minimo consentito. Per poter proseguire, la durata della dilazione sarà automaticamente modificata, passando a {{this.installmentNumber}} quote di {{this.installmentAmount}} €.</p>
            <div class="cmd">
                <button type="button" class="btn tertiary" [disabled]="clicked" id="dialog-close" 
                        (click)="closeTooltip(true);"> Accetta</button>
                <button type="button" class="btn tertiary" id="dialog-close" 
                        (click)="closeTooltip()"> Torna al sito dell'esercente</button>
            </div>
        </div>
    </div>
</ng-container>

<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
  <section class="end centered" *ngIf="result == 'ok' || result == 'SUCCESS'">
            <div class="wrap">
      <img src="./assets/img/logo.png" alt="APPpago">
      <h2>Grazie {{ procedureOk.firstName }}, hai completato correttamente il processo di richiesta.</h2>
      <p><span>La dilazione di {{ formatAmount(procedureOk.loanTotalAmount) }} in {{ procedureOk.loanInstalments }} QUOTE è stata accettata.</span><br/>Tra pochi secondi verrai reindirizzato automaticamente sul sito da cui hai iniziato il processo.<br/>Se non vieni reindirizzato in automatico puoi premere il bottone qui sotto</p>
          <button class="btn tertiary" (click)="goToMerchant()">Torna al sito dell'esercente</button>
      </div>
  </section>

  <section class="end error centered" *ngIf="result == 'ko'">
    <div class="wrap">
      <img src="./assets/img/error.svg" alt="Error">
      <h2>La tua valutazione creditizia è negativa</h2>
        <p>Siamo spiacenti, la tua valutazione creditizia <b>non consente</b> di proseguire. <b>Contatta l'help desk</b> per maggiori informazioni <b>o riprova</b> con una nuova transazione.</p>
        <br>
        <button class="btn green" (click)="goToMerchant()">Torna al sito dell'esercente</button>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'generic-ko'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Qualcosa è andato storto</h2>
        <p>Siamo spiacenti, ma il flusso della pratica si è interrotto in modo anomalo. Torna al sito dell'esercente e riprova con una nuova transazione.</p>
        <br>
        <button class="btn green" (click)="goToMerchant()">Torna al sito dell'esercente</button>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'charge-failed-ko' || result == 'ERROR'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Addebito prima quota non riuscito</h2>
        <p>C'è stato un errore durante l'addebito della prima quota sul metodo di pagamento da te fornito. Torna sul sito dell'esercente ed inizia nuovamente il flusso.</p>
        <br>
        <button class="btn green" (click)="goToMerchant()">Torna al sito dell'esercente</button>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'ERROR'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Addebito prima quota non riuscito</h2>
        <p>Siamo spiacenti, ma la tua banca non aderisce al servizio richiesto per il pagamento tramite IBAN. Inserisci una nuova dilazione utilizzando un metodo di pagamento diverso.</p>
        <br>
        <button class="btn green" (click)="goToMerchant()">Torna al sito dell'esercente</button>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'timeout'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>La sessione è scaduta.</h2>
      <p>Siamo spiacenti, la tua sessione è andata in timeout.<br>Torna sul sito dell'esercente e ritenta la transazione</p>
      <button class="btn green" (click)="goToMerchant()">Torna al sito dell'esercente</button>
    </div>
  </section>
  
  <section class="end error centered" *ngIf="result == 'consumer-data-error'">
    <div class="wrap">
      <img src="./assets/img/error.svg" alt="Error">
      <h2>È stato riscontrato un problema sui dati anagrafici utilizzati</h2>
      <p>Si prega di contattare l'HelpDesk per ulteriori dettagli e l'eventuale soluzione.</p>
      <br>
      <button class="btn green" (click)="goToMerchant()">Torna al sito dell'esercente</button>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'contract-error'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Errore nel recupero del contratto</h2>
      <p>Siamo spiacenti ma non è stato possibile creare correttamente il contratto per un errore tecnico. Si prega di riprovare fra qualche minuto, creando una nuova procedura dal sito del merchant.</p>
      <br>
      <button class="btn green" (click)="goToMerchant()">Riprova</button>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'invalid-link'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Link scaduto o non valido</h2>
      <p>Attenzione, il link che stai utilizzando non è più valido.<br>
        Controlla la mail per essere sicuro di non aver già ricevuto una mail successiva con il nuovo link.<br>
        In caso non dovessi ricevere una nuova comunicazione, contatta l'<b>Helpdesk</b> al numero <b>800 085572</b> (lun ven 9-18)
      </p>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'invalid-url'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Link non valido</h2>
      <p>Il link utilizzato non è corretto. Si prega di rivolgersi all'<b>Helpdesk</b> al numero <b>800 085572</b> (lun ven 9-18) per approfondire le motivazioni.
      </p>
    </div>
  </section>

  <section class="end error centered" *ngIf="result == 'invalid-installment-number'">
    <div class="wrap">
      <img src="./assets/img/alert-ico.svg" alt="Warning">
      <h2>Link non valido</h2>
      <p>
        Il link utilizzato non è corretto.<br><br>
        La durata della cessione richiesta non è conforme alle possibili soluzioni offerte dal servizio.<br><br>
        Verificare la richiesta con l'esercente e, in caso si ripresenti il problema, è possibile contattare l'HelpDesk al numero <b>800 085572</b> (lun ven 9-18).
      </p>
      <br>
      <button class="btn green" (click)="goToMerchant()">Riprova</button>
    </div>
  </section>


</ng-container>

<app-notifications></app-notifications>
<div class="loading" *ngIf="loading"></div>

<ng-container *ngIf="!loading">
    <section class="head">
        <div class="wrap">
          <div class="progress" [attr.data-step]="user.getStep()"></div>
          <div>
            <h1>Scegli come vuoi procedere</h1>
            <h2>Seleziona "Partita Iva" se intendi procedere come ditta individuale o libero professionista</h2>
          </div>
        </div>
      </section>

      <section class="fill">
        <fieldset><legend style="display: none;">Form</legend>
            <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>
            <div class="wrap">
                <div class="form">
                    <div class="field" style="flex-direction: column;">
                        <div class="tabs">
                            <input name="method" type="radio" id="method-1" class="input" (click)="subjectPerson()"/>
                            <label for="method-1">Voglio proseguire come persona fisica</label>

                            <input name="method" type="radio" id="method-2" class="input" (click)="subjectVatCode()"/>
                            <label for="method-2">Voglio procedere con Partita Iva</label>
                            <div class="tab">
                                <div class="form">
                                    <ng-container *ngIf="formConfiguration">
                                        <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
                                          <form-inputtext class="field half" [fields]="form" *ngSwitchCase="'input-text'" (emitter)="checkEnableButton()">
                                          </form-inputtext>
                                        </ng-container>
                                      </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
      </section>
      <section class="commands">
        <div class="wrap">
          <div class="cmd">
            <!-- <button *ngIf="this.canNavigateBack" class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg"></button> -->
            <button class="btn tertiary" (click)="sendForm()" [disabled]="!enabledButton">Prosegui</button>
          </div>
        </div>
      </section>

</ng-container>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { resolve } from 'dns';
import { env } from 'process';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { RouterService } from '../services/router.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.css']
})
export class EndComponent implements OnInit {

  constructor(protected route : ActivatedRoute,
              private user : UserService,
              private router : RouterService,
              private service : ServerService,
              private notification: NotificationService,
              private onboarding : OnBoardingService) { }

  loading : boolean = true;
  result;
  merchantTx : string = '';
  merchantId : string = '';
  conselCode : string = '';
  procedureId : string = '';
  procedureOk = {
    firstName : '',
    loanTotalAmount : '',
    loanInstalments : ''
  };
  canForceMaxInstallments: boolean = false;
  installmentNumber: string;
  otp: string = '';
  merchantRedirect: string = '';
  invalidField: string = '';

  ngOnInit() {

    this.procedureId = this.onboarding.getProcedureId();
    this.merchantId = this.user.getProfile()['merchantId'];
    this.merchantRedirect = this.user.getProfile()['merchantRedirect'];

    this.route
        .params
        .subscribe( params => {
          this.result = params['slug'].split('?')[0];

          this.service.logWebAnalytics({
            procedureId : this.onboarding.getProcedureId()
            , path : '/end/' + this.result
            , exitFlag : 'N'
            , transaction : this.user.getMotifSessionId()
            , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
          }).subscribe(res => {}, err => {});
    });
    
    this.route.queryParams.subscribe( queryparams => {
      this.otp = queryparams['otpCode'];
      this.invalidField = queryparams['invalidField'];
    });

    if (this.otp != null && this.otp != "") {
      this.service.getTokenMobile(this.merchantId, this.procedureId, this.otp).subscribe(res => {
        if(res['accessToken'] && res['refreshToken']) {
          this.user.setTokens(res);

          if (this.onboarding.getProcedureId() != null) {
            this.getProcedure();
          } else {
            this.loading = false;
          }

        }
      }, err => {
        this.notification.showNotificationModal(err);
      });
    } else {
      if (this.onboarding.getProcedureId() != null) {
        this.getProcedure();
      } else {
        this.loading = false;
      }
    }

    if (this.result === 'ERROR' || this.result === 'SUCCESS') {
      
      this.service.logWebAnalytics({
        procedureId : this.onboarding.getProcedureId()
        , path : '/pisp-redirect'
        , exitFlag : 'S'
        , transaction : this.user.getMotifSessionId()
        , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
      }).subscribe(res => {}, err => {});
      
      const req = this.result;

      this.service.creditTransferPisp(req).subscribe(res => {
      }, err => {
        this.notification.showNotificationModal(err);
        this.router.navigate(["end/generic-ko"]);
      })
    }

    if(!environment.isTest) {
      setTimeout(() => { this.goToMerchant() }, 15000);
    }

  }

  
  goToMerchant() {
    if (this.merchantRedirect != null && this.merchantRedirect !== '') {
      this.loading = true;
      let url = this.merchantRedirect;

      if (this.result === 'ko') {
        url += '?result=ko&error_code=520&error_desc=creditworthiness-denied';
      } else if (this.result === 'timeout' || this.result === 'contract-error') {
        url += '?result=ko&error_code=521&error_desc=timeout';
      } else if (this.result === 'generic-ko') {
        url += '?result=ko&error_code=522&error_desc=generic-error';
      } else if (this.result === 'charge-failed-ko' || this.result === 'ERROR') {
        url += '?result=ko&error_code=523&error_desc=charge-failed';
      } else if (this.result === 'ok' || this.result === 'SUCCESS') {
        url += '?result=ok';
      } else if (this.result === 'consumer-data-error') {
        url += '?result=ko&error_code=524&error_desc=consumer-data-error';
      } else if (this.result === 'invalid-installment-number') {
        url += '?result=ko&error_code=526&error_desc=invalid-installment-number';
      } else {
        url += '?result=ko&error_code=525&error_desc=generic-error';
      }

      url += '&merchantTx=' + this.shiftMerchantTxUnderscoreIfPresent() //this.merchantTx 
        + '&contract_number=' + this.conselCode;

      if (this.canForceMaxInstallments) {
        url += "&installmentNumber=" + this.installmentNumber;
      }

      if (this.procedureId !== null) {
        this.service.checkDigit(this.procedureId).subscribe(res => {
          url += '&checkDigit=' + res.checkDigit;
          window.location.replace(url);
        }, err => {
          url += '&checkDigit=';
          window.location.replace(url);
        })
      } else {
        this.service.checkDigitKo(this.merchantId, this.merchantTx).subscribe(res => {
          url += '&checkDigit=' + res.checkDigit;
          window.location.replace(url);
        }, err => {
          url += '&checkDigit=';
          window.location.replace(url);
        })
      }
    }
  }

  shiftMerchantTxUnderscoreIfPresent() {
    if(this.merchantTx.includes('_')) {
      this.merchantTx = this.merchantTx.split('_').shift();
      return this.merchantTx;
    } else return this.merchantTx;
  }

  formatAmount(amount) : string {
    if (amount == null) {
      return "";
    }
    
    return amount.split('.')[0] + ',' + amount.split('.')[1].substr(0,2);
  }

  getProcedure() {
    this.service.getProcedure().subscribe(res => {
      this.merchantTx = res.olMerchantTrxId;
      this.conselCode = res.conselCode;
      this.procedureId = res.id;
      this.installmentNumber = res.loanInstalments;
      this.merchantId = res.merchantData.id;

      this.procedureOk.firstName = res.consumerData.firstname;
      this.procedureOk.loanInstalments = res.loanInstalments;
      this.procedureOk.loanTotalAmount = res.loanTotalAmount;
      if (this.user.getProfile()['merchantRedirect'] == null || this.user.getProfile()['merchantRedirect'] == '') {
        this.service.checkDigit(this.procedureId).subscribe(res2 => {
          this.merchantRedirect = res2.merchantRedirect;
        }, err => {
        });
      } else {
        this.merchantRedirect = this.user.getProfile()['merchantRedirect'];
      }

      this.loading = false;
  
    }, err => {

      this.loading = false;

    });
  }

}



<app-notifications></app-notifications>
<div class="loading" *ngIf="loading">
<div class="loading-message" *ngIf="enabledButton"><p>Lettura documenti in corso...</p></div></div>

<app-dialog-upload-mobile #dialogUploadMobile (close)="closeDialogSMSUpload($event)"></app-dialog-upload-mobile>

<ng-container *ngIf="!loading">
    <section class="head">
      <div class="wrap">
        <div class="progress" [attr.data-step]="user.getStep()"></div>
        <div>
          <h1>Inserisci i tuoi documenti e conferma i dati</h1>
          <h2>Carica un documento a scelta tra carta d'identità, passaporto e patente + tessera sanitaria e valida i dati acquisiti</h2>
        </div>
      </div>
    </section>

    <section class="fill" *ngIf="sendByPhone">
      <button *ngIf="this.canNavigateBack" class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>

      <fieldset class="row"><legend style="display: none;">Form</legend>
        <div class="wrap">
          <div class="form">
            <!-- <div style="display: flex; flex-wrap: wrap;"> -->
              <div  class="legend">
                <h2>Passa al caricamento da telefono</h2>
                <p>Clicca sul pulsante sottostante per ricevere un SMS che ti permetterà di procedere all'upload dei documenti tramite il tuo dispositivo Mobile.</p>
              </div>
            <!-- </div> -->
          </div>
        </div>
        <br/><br/>
      <div class="commands mobile-btn">
        <div class="legend">
          <img src="../../assets/img/smartphone_APPpago.png">
        </div>
        <br/>
        <button class="btn outline" (click)="sendSMSUpload()">Ricevi SMS</button>
      </div>
      </fieldset>
    </section>

    
    <ng-container *ngIf="!sendByPhone">
      <section class="fill">
        <button class="btn btn-back" (click)="navigateBack()"><img src="assets/img/back-arrow-mobile.svg" alt="image"></button>
        
        <ng-container *ngIf="!sendByImage">
          <fieldset class="sms-box row"><legend style="display: none;">Form</legend>
            <div class="wrap">
              <div class="form">
                <!-- <div style="display: flex; flex-wrap: wrap;"> -->
                  <div  class="field">
                    <div>
                      <h2>Preferisci caricare o fotografare i documenti dal cellulare?</h2>
                      <p>Clicca su RICEVI SMS e potrai procedere all'upload dei tuoi documenti tramite il tuo dispositivo mobile.</p>
                    </div>
                    <div class="commands">
                      <button class="btn green" (click)="sendSMSUpload()">Ricevi SMS</button>
                    </div>
                  </div>
                <!-- </div> -->
              </div>
            </div>
          </fieldset>
        </ng-container>
        <br>
        <div class="wrap upload-documents">
          <p>Assicurati che le tue fotografie o scansioni siano:</p>
          <ul>
            <li>di un documento valido</li>
            <li>leggibili, senza riflessi e con i quattro angoli completamente visibili</li>
            <li>il formato deve essere JPEG o JPG e le dimensioni inferiori a 5 Mb</li>
          </ul>
        </div>
        <fieldset class="row"><legend style="display: none;">Form</legend>
          <div class="wrap upload-documents">
            <div *ngIf="!validIdDocument" class="upload-documents__single-container">
              <h2>Documento d'identità</h2>
              <div class="form" *ngIf="formConfiguration" >
                <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
                  <form-inputselect class="field select-white" [fields]="form" [borderClass]="borderClassSelect" *ngSwitchCase="'input-select'" (emitter)="enableIdUpload()" ></form-inputselect>
                  <ng-container  *ngIf="idVisible">
                  <form-inputfile class="field half" [fields]="form" [textClass]="textClassId" *ngSwitchCase="'input-file-identity-card'" (emitter)="checkEnableHicDocument()" [disabled]="!enabledId"></form-inputfile>
                </ng-container>
              </ng-container>
              </div>
           </div>
            <!-- </div> -->
          <!-- </div>
        </fieldset>
        <fieldset class="row">
          <div class="wrap"> -->
            <ng-container *ngIf="hicVisible">
              
            <div *ngIf="!validHicDocument" class="upload-documents__single-container">
              <!-- <div *ngIf="hicVisible"> -->
                <h2>Tessera sanitaria</h2>
                <div class="form i-card"*ngIf="formConfiguration">
                <ng-container *ngFor="let form of formConfiguration; let i = index;" [ngSwitch]="form.id">
                  <form-inputfile class="field half" [fields]="form" *ngSwitchCase="'input-file-medical-card'" (emitter)="checkEnableButton()"></form-inputfile>
                </ng-container>
                </div>
              <!-- </div> -->
            </div>
          </ng-container>
              </div>
            <!-- </div> -->
          <!-- </div> -->
        </fieldset>
      </section>
    </ng-container>
    <section class="commands">
      <div class="wrap">
        <div class="cmd">
                      <!-- <button class="btn secondary outline small" routerLink="/privacy" routerLinkActive="active">Indietro</button> -->
                      <button class="btn btn-back footer" (click)="navigateBack()"><img src="assets/img/back-arrow-desktop.svg" alt="image"></button>
                      <button class="btn tertiary" (click)="sendForm()" [disabled]="!enabledButton">Prosegui</button>
          <!-- <button class="btn" routerLink="/summary" routerLinkActive="active" (click)="sendForm()">Avanti</button> -->
        </div>
      </div>
    </section>
  
</ng-container>

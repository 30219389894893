<ng-container *ngIf="show">
    <div class="dialog" id="dialog">
        <div class="loading" *ngIf="loading"></div>
        <div *ngIf="!loading">
            <div style="display: flex;">
                <img src="./assets/img/alert_icon.png" width="140px" height="180px" class="sms-upload-img">
                <div>
                    <h3><b>SMS Inviato</b></h3>
                    <p>
                        Abbiamo inviato sul tuo cellulare un <b>SMS</b> contenente un <b>link per poter scattare le foto dei tuoi documenti</b>. Al termine della procedura questa pagina verrà chiusa in automatico.
                        <br>
                        Se non ricevi l'SMS, <a href="#" (click)="closeTooltip()"><u>clicca qui</u></a> e riprova.
                    </p>
                    <div class="cmd">
                        <button type="button" class="btn tertiary small" id="dialog-close" 
                                (click)="closeTooltip()"> Chiudi</button>
                    </div>
                </div>
            </div>
            <!--<p>
                 Riceverai un SMS per continuare sul tuo telefono. Il messaggio conterrà un link da aprire sul telefono, per poter scattare le foto dei tuoi documenti.
                N.B. Non chiudere questo pop-up o la pagina del tuo browser, altrimenti il flusso sarà interrotto e dovrai ricominciare da capo.
                Al termine della procedura sul tuo telefono, questa pagina si aggiornerà in maniera automatica.<br><br>
                
                Se non ricevi il messaggio, clicca sul bottone "chiudi" e riprova.
            </p>
            <div class="cmd">
                <button type="button" class="btn tertiary" id="dialog-close" 
                        (click)="closeTooltip()"> Chiudi</button>
            </div> -->
        </div>
    </div>
</ng-container>

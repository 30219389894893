import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from "../services/server.service";
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment';
import { NotificationService } from '../services/notification.service';
import { DialogChangeInstallmentsComponent } from './dialog-change-installments/dialog-change-installments.component';
import { RouterService } from '../services/router.service';

declare var GestPay;
declare var BrowserEnabled;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {

  constructor(private service: ServerService,
              private onboarding: OnBoardingService,
              private _ngZone : NgZone,
              private user: UserService,
              public route: RouterService,
              private notification: NotificationService,
              public cd: ChangeDetectorRef) { }

  formConfiguration;
  paymentType : string = '';
  loading : boolean = true;
  enabledButton : boolean = false;
  taxCode : string = '';
  disableBAPayment : boolean = false;
  olPaymentMethod : string = 'T';
  loanInstallmentAmount : string = '0,0';
  forcedInstallments = {}
  canNavigateBack: boolean = true;
  vatCodeEnabled: boolean = false;
  prepaidAllowed: boolean = false;

  @ViewChild('dialogChangeInstallments') dialogChangeInstallments : DialogChangeInstallmentsComponent;

  ngOnInit() {
    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/payment-method' 
      , exitFlag : 'N'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});

    this.service.loadJson('payment-method', 'paymentMethod').then(res => {
      this.formConfiguration = this.onboarding.paymentMethodConfig;
      this.service.getProcedure().subscribe(res => {
        if(res.hasOwnProperty('olPaymentMethod')) {
          this.olPaymentMethod = res.olPaymentMethod;
          this.checkOlPaymentMethod(this.olPaymentMethod);
        }
        this.taxCode = res.consumerData.taxCode;
        this.formConfiguration['config-bank-account'].find(elem => elem.server === 'taxCode').value = res.consumerData.taxCode;
        if (res.status === 'init_app' && (res.state === 'card_rejected' || res.state === 'token_rejected' || res.state === 'installment_error')) {
          this.notification.showNotificationModal('Non è stato possibile verificare la carta di credito utilizzata', false)
        }
       
        // this.loanInstallmentAmount = res.loanInstalmentAmount
        this.loanInstallmentAmount = parseFloat(res.loanInstalmentAmount).toFixed(2).replace('.' ,',');
        
        
        this.vatCodeEnabled = res.vatCodeEnabled == true;
        
        if (this.user.getProfile().installmentNumber != null && this.user.getProfile().installmentNumber != "" && !this.vatCodeEnabled) {
          this.canNavigateBack = false;
        }
        
        this.prepaidAllowed = res.prepaidAllowed == true;

        this.loading = false;
      }, err => {
        this.loading = false;
        this.notification.showNotificationModal(err);
      })
    })
  }

  checkOlPaymentMethod(method) {
    if(method == 'C') { this.changePaymentType('credit-card');
      } else if(method == 'I' || method == 'B') { this.changePaymentType('bank-account'); }
  }

  changePaymentType(type) {
    this.paymentType = type;
    this.checkEnableButton();
  }
  
  sendForm() {
    if(environment.isTest && this.paymentType === 'credit-card'){
      window.location.replace('http://localhost:4200/urlback?PARes=ABC')
    } else {
      this.loading = true;
      let output = {};
      if (this.paymentType === 'credit-card') {
        this.encrypt();
      } else if (this.paymentType === 'bank-account') {
        this.service.checkIban({iban : this.formConfiguration['config-bank-account'].find(elem => elem.server === 'iban').value}).subscribe(res => {
          output = this.onboarding.mappingOutputPaymentMethodBankAccount(this.formConfiguration['config-bank-account']);
          this.service.patchProcedure(output).subscribe(res => {
            this.loading = false;
            this.user.nextStep();
            this.route.navigate(['/contacts']);
          }, err => {this.loading = false; this.notification.showNotificationModal(err)})
        }, err => {this.loading = false; this.notification.showNotificationModal(err)})
    }
   }
  }

  encrypt() {
    let output = this.onboarding
                     .mappingOutputPaymentMethodCreditCard(this.formConfiguration['config-credit-card']);
    let date = output['dueDateMonth'] + output['dueDateYear'];
    this.service.encrypt(date)
        .subscribe(res => {
          localStorage.setItem('shopLogin',res.shopLogin);
          localStorage.setItem('encryptedString',res.encryptedCard);
          if(BrowserEnabled) this.createPage(res.encryptedCard, res.shopLogin);
        }, err => { this.loading = false; this.notification.showNotificationModal(err) });
  }

  //creazione del pagamento
  createPage(encrypt, shoplogin) {      
    let that = this;
      GestPay.CreatePaymentPage(shoplogin, encrypt, function resultCreate(result) {
        if(NgZone.isInAngularZone()) {
            that.checkCreatePage(result.ErrorCode);        
          } else {
            that._ngZone.run(() => {
              that.checkCreatePage(result.ErrorCode);
            });
        }  
      });
  }

  //check errore di creazione del pagamento
  checkCreatePage(error) {
    let that = this;
      if(error == '10') {
          that.sendPayment();
        } else {
          that.loading = false; that.notification.showNotificationModal("L'operazione non è andata a buon fine", false)
      }
  }

  sendPayment() {
    let that   = this;
    let output = that.onboarding
                     .mappingOutputPaymentMethodCreditCard(that.formConfiguration['config-credit-card']);
    
    let obj = {
      CC    : output['cardNumber'],
      EXPMM : output['dueDateMonth'],
      EXPYY : output['dueDateYear'],
      Name  : that.user.getProfile().lastname + ' ' + that.user.getProfile().firstname,
      CVV2  : output['cvv'],
      Email : that.user.getProfile().email
    };

    GestPay.SendPayment(obj, function resultSend(result) {    
        if(NgZone.isInAngularZone()) {
          that.checkSendPage(result);
            } else {
              that._ngZone.run(() => {
                that.checkSendPage(result);
              });
          }  
    }); 
  }

  checkSendPage(result) {
    let that = this;
    
    if(result.ErrorCode == '0') {
        that.decrypt(result.EncryptedString);
    } else if(result.ErrorCode == '8006') {
            that.send3ds(result);
    } else {
        that.loading = false; that.notification.showNotificationModal("Non è stato possibile effettuare l'operazione", false)
    }
  }

  decrypt(decrypt) {
    let body = {
      shopLogin : localStorage.getItem('shopLogin'),
      cryptedString : decrypt
    }
    this.service.decrypt(body)
        .subscribe(res => {
          this.user.nextStep();
          this.route.navigate(['/contacts']);
        }, err => { 
          if (err.error.message.code === 'EM-P0090') {
            this.forcedInstallments = err.error.message
            this.dialogChangeInstallments.openTooltip();  
          } else {
            this.loading = false;
            this.notification.showNotificationModal(err);
          }
        });
  }

  send3ds(result) {
    localStorage.setItem('TransKey',result.TransKey);

    this.service.logWebAnalytics({
      procedureId : this.onboarding.getProcedureId()
      , path : '/payment-method'
      , exitFlag : 'S'
      , transaction : this.user.getMotifSessionId()
      , merchantId : this.user.getProfile() == null ? null : this.user.getProfile().merchantId
    }).subscribe(res => {}, err => {});
    
    window.location.replace(environment.gestpay_3ds + '?a=' + localStorage.getItem('shopLogin') + '&b=' + result.VBVRisp + '&c=' + environment.gestpay_3ds_urlback);
  }

  checkEnableButton() {
    if (this.formConfiguration['config-' + this.paymentType]) {
      this.enabledButton = true;
      this.formConfiguration['config-' + this.paymentType].forEach(elem => {
        if(!elem.isvalid) { 
          this.enabledButton = false;
        }
      });
    }
  }

  navigateBack() {
    if (this.user.getProfile().installmentNumber != null && this.user.getProfile().installmentNumber != "") {
      if (this.vatCodeEnabled) {
        this.user.backStep();
        this.route.navigate(['/select-profile'])
      } 
    } else {
      this.user.backStep();
      this.route.navigate(['/duration'])
    }
  }

  closeDialogChangeInstallments(error?) {
    if (error) {
      this.notification.showNotificationModal(error, true)
    }

    this.loading = false;
  }
}

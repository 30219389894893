import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { OnBoardingService } from 'src/app/services/onboarding.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dialog-upload-mobile',
  templateUrl: './dialog-upload-mobile.component.html',
  styleUrls: ['./dialog-upload-mobile.component.css']
})

export class DialogUploadMobileComponent implements OnInit {

  @Output() close : EventEmitter<any> = new EventEmitter<any>();

  constructor(private service : ServerService,
              private user : UserService,
              private onboarding : OnBoardingService) { }

  show : boolean = false;
  loading : boolean = true;

  ngOnInit() { }

  openTooltip() {
    this.show = true;
    document.body.classList.add('locked');
    const req = {
      recipient : this.user.getProfile()['phone'],
      procedureId: this.onboarding.getProcedureId(),
      merchantId: this.user.getProfile()['merchantId']
    };
    this.service.sendSMSUploadDocument(req).subscribe(res => {
      this.loading = false;
    }, err => {
      this.closeTooltip(err)
    })
  }

  closeTooltip(err = false) {
    document.body.classList.remove('locked');
    if (err) {this.close.emit(err);} else {this.close.emit();}
    this.show = false;
  }

}

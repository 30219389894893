import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { NotificationService } from '../services/notification.service';
import { OnBoardingService } from '../services/onboarding.service';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload-mobile.component.html',
  styleUrls: ['./documents-upload-mobile.component.css']
})
export class DocumentsUploadMobileComponent implements OnInit {

  constructor(protected route : ActivatedRoute,
              public router: Router,
              private service : ServerService,
              private onboarding: OnBoardingService,
              private notification: NotificationService,
              private user : UserService) { }

  formConfiguration;
  procedureId : string;
  merchantId : string;
  otpRef : string;
  loading : boolean = true;
  enabledButton : boolean = false;
  procedureFinished : boolean = false;
  isDocument: boolean = false;
  validHicDocument: boolean = false;
  hicVisible: boolean = false;

  ngOnInit() {

    this.service.loadJson('documents-upload', 'documents').then(res => {
      this.formConfiguration = this.onboarding.documentsConfig['config-documents-upload'];
      this.route
        .params
        .subscribe( params => {
          this.merchantId = params['merchantId'];
          this.procedureId = params['procedureId'];
          this.otpRef = params['otpRef'];
          this.loginMobile();
          
          this.service.logWebAnalytics({
            procedureId : this.procedureId
            , path : '/documents-upload-mobile'
            , exitFlag : 'N'
            , transaction : this.user.getMotifSessionId()
            , merchantId : this.merchantId
          }).subscribe(res => {}, err => {});

        });
    })
  }
  toggleDocument($event){
    let clickedElement = $event.target || $event.srcElement;
    if(clickedElement.nodeName === "BUTTON") {
      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".is-active");
      // if a Button already has Class: .is-active
      if(isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("is-active");
      }
      clickedElement.className += " is-active";
      this.isDocument = true;
      // this.isDocument = !this.isDocument;
    }
  }

  loginMobile() {
    if (this.otpRef !== null && this.otpRef !== '') {
      this.service.getTokenMobile(this.merchantId, this.procedureId, this.otpRef).subscribe(res => {
        if(res['accessToken'] && res['refreshToken']) {
          //this.loading = false;
          this.user.setTokens(res);
          //this.enabledButton = true;
          this.getProcedureMobile();
        } else {
          this.loading = false;
          this.notification.showNotificationModal("Non è stato possibile iniziare il processo, torna al sito del commerciante.",false);
        }
      }, err => {this.loading = false; this.notification.showNotificationModal(err);})
    } else {
      this.loading = false;
          this.notification.showNotificationModal("Non è stato possibile iniziare il processo, torna al sito del commerciante.",false);
    }
  }

  getProcedureMobile() {
    this.service.getProcedureMobile(this.merchantId,this.procedureId)
                .subscribe(procedure => {
      if(procedure.hasOwnProperty('olDocTypes')) {
        this.formConfiguration.forEach(element => {
          if(element.server == 'documentType') {
            element.values = element.values.filter((elem) => 
              procedure.olDocTypes.some(x => elem.key === x.toLowerCase()));
          }
        });
      }
      this.loading = false;
      this.enabledButton = true;
    }, err => { this.loading = false; this.notification.showNotificationModal(err); });
  }

  sendForm() {
    this.loading = true;
    this.service.uploadFileFromMobile(this.onboarding.mappingOutputUploadDocument(this.formConfiguration), {merchantId: this.merchantId, procedureId: this.procedureId})
        .subscribe(res => {
          this.loading = false;
          this.procedureFinished = true;

          this.service.logWebAnalytics({
            procedureId : this.procedureId
            , path : '/documents-upload-mobile'
            , exitFlag : 'S'
            , transaction : this.user.getMotifSessionId()
            , merchantId : this.merchantId
          }).subscribe(res => {}, err => {});

    }, err => { this.loading = false; this.notification.showNotificationModal(err) })
  }

  checkEnableHicButton(){
    if(!this.validHicDocument) {  
      let enable = true;
      this.formConfiguration.forEach(elem => {
        if(elem.id == 'input-file-identity-card' && !elem.isvalid) { 
          enable = false;
        }
      });
      this.hicVisible = enable;
      this.checkEnableButton();
    } else {
      this.checkEnableButton();
    }
  }

  // checkEnableButton() {
  //   this.enabledButton = true;
  //   this.formConfiguration.forEach(elem => {
  //     if(!elem.isvalid) { 
  //       this.enabledButton = false;
  //     }
  //   });
  // }

  checkEnableButton() {
    this.enabledButton = true;

    this.formConfiguration.forEach(elem => {
      if(
        !(elem.isvalid 
        || ((elem.id === 'input-file-identity-card' || elem.id === 'input-select')) 
        || (elem.id === 'input-file-medical-card' && this.validHicDocument)
       )
      ) { 
        this.enabledButton = false;
      }
    });

  }

  selectDocument(inputDocType : string) {
      this.formConfiguration.find(elem => elem.id === "input-select").value = inputDocType;
  }

}
